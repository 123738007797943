import React from "react";


export default function CatherineJoinsTeamPost(props){

  return (<section class="pt-0 pt-md-0 pb-0">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-md-12 col-lg-10 col-xl-9">
                  <p>Hectiq.ai team is happy to announce the arrival of Catherine as an Ai Research Scientist. She will work along us to pursue new industrial challenges while bringing balance to the force.
                  </p>
                  <img src="/assets/img/photos/catherine.jpg" class="img-fluid rounded lift lift-lg mt-8 mb-8 mx-auto d-block w-lg-25" alt="..."/>
                  <h5 class="text-uppercase mt-8 text-info">Short Bio</h5>
                  <p class="text-secondary">
                    Catherine completed her postgraduate studies at the University de Moncton, specializing in AI-based steganalysis. After acquiring her degree, Catherine worked as an AI researcher in different R&D departments (INO, Zilia), broadening and sharpening her proficiency with multiple frameworks. Her passion brought her naturally to hectiq.ai, where she gets to apply and adapt innovative methods in machine learning toward industrial problematics.
                  </p>
                </div>
              </div> 
            </div> 
          </section>)

}